:root {
  --primary: #4a90e2;
  --primary_light_01: #7cc5fe;
  --primary_light_02: #6ba9f0;
  --primary_light_03: #6097DF;
  --primary_dark_01: #4A64A0;
  --primary_transparent: #4a90e280;
  --light_01: #ffffff;
  --light_02: #fafafa;
  --light_03: #ebf6fc;
  --grey: #575858;

  --gradient_primary: linear-gradient(to right, #7cc5fe, #6ba9f0);
  --gradient_secondary: linear-gradient(180deg, #ff755a, #ff595a);

  --shadow: #1c234c37;
  --shadow_02: #0e2d9222;
  --shadow_03: #242e3c50;
  --shadow_04: #57585850;
  --shadow_05: #7d81ce50;
  --shadow_06: #57585815;

  --content-font: 'Open Sans', 'Arial', sans-serif;
  --heading-font: 'Oswald', 'Arial', sans-serif;
}

*,
::after,
::before {
  box-sizing: border-box;
}

html,
body,
div,
span,
applet,
object,
iframe,
h1,
h2,
h3,
h4,
h5,
h6,
p,
blockquote,
pre,
a,
abbr,
acronym,
address,
big,
cite,
code,
del,
dfn,
em,
img,
ins,
kbd,
q,
s,
samp,
small,
strike,
strong,
sub,
sup,
tt,
var,
b,
u,
i,
center,
dl,
dt,
dd,
ol,
ul,
li,
fieldset,
form,
label,
legend,
table,
caption,
tbody,
tfoot,
thead,
tr,
th,
td,
article,
aside,
canvas,
details,
embed,
figure,
figcaption,
footer,
header,
hgroup,
menu,
nav,
output,
ruby,
section,
summary,
time,
mark,
audio,
video {
  margin: 0;
  padding: 0;
  border: 0;
}

html {
  scroll-behavior: smooth;
}

body {
  color: var(--grey);
  font-size: 16px;
  font-family: var(--content-font);
  font-weight: 400;
  line-height: 1.5;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: var(--heading-font);
  line-height: 1.2;
  margin-bottom: 0.5rem;
}

h2,
h3 {
  font-size: 1.75rem;
  text-transform: uppercase;
}

h5 {
  font-size: 1.5rem;
}

a,
a:hover {
  color: inherit;
  text-decoration: none;
}

hr {
  border: none;
  border-bottom: 2px solid var(--grey);
  margin: 1.5rem auto;
}
